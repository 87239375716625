<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              預會員列表
              <v-spacer></v-spacer>
              <!-- 需要後台filter -->
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="搜尋外部ID"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  :disabled="!isLoaded"
                  @click="
                    pagination.page = 1;
                    getDataFromApi();
                  "
                >
                  {{ $t(`common["Update"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                ref="prememberTable"
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                item-key="id"
                :options.sync="pagination"
                :server-items-length="total"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status === 'queued'"> 新增 </span>
                  <span v-else-if="item.status === 'reserved'"> 預會員 </span>
                  <span v-else-if="item.status === 'completed'"> 已完成 </span>
                  <span v-else-if="item.status === 'cancelled'"> 已取消 </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        small
                        color="blue darken-5"
                        outlined
                        :disabled="props.item.status !== 'reserved'"
                        @click="onReturnItemClick(props.item)"
                      >
                        回收
                        <!-- <v-icon>fas fa-arrow-left</v-icon> -->
                      </v-btn>
                    </template>
                    <span>回收</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding" v-if="total > 0">
              <Pagination
                :pagination="pagination"
                :total="total"
                :loading="!isLoaded"
                showItemsPerPage
                dataTable="prememberTable"
                @update="getDataFromApi"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchPrememberList } from "src/apis/fetchData";
import { refundPrememberPoint } from "src/apis/updateData";
import { formatNumber, checkPermission } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  name: "PreMembers",
  components: { Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      isLoaded: true,
      total: 0,
      tableData: [],
      tableHeaders: [
        {
          text: "會員ID",
          sortable: false,
          value: "prememberKey",
        },
        {
          text: "外部ID",
          sortable: false,
          value: "completeId",
        },
        {
          text: "任務名稱",
          sortable: false,
          value: "missionName",
        },
        {
          text: "狀態",
          sortable: false,
          filterable: false,
          value: "status",
        },
        {
          text: "建立時間",
          sortable: false,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "更新時間",
          sortable: false,
          filterable: false,
          value: "updatedAt",
        },

        { text: "", value: "actions", sortable: false },
      ],
      search: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return !this.isLoaded;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountManage",
          "EditPremembers",
          true
        );
      }
      return false;
    },
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(function () {
          self.getDataFromApi();
        }, 200);
      },
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
  },
  methods: {
    init() {
      this.getDataFromApi();
    },
    getDataFromApi() {
      this.isLoaded = false;
      this.getPremembers()
        .then((data) => {
          this.tableData = [...data.items];
          this.total = data.total;
          this.isLoaded = true;
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getPremembers() {
      this.tableData = [];

      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        console.log("pagination", this.pagination);
        let query = {
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          sortBy: sortBy && sortBy.length ? sortBy[0] : null,
          desc: sortDesc,
          search: this.search,
        };
        let items = [];
        let total = 0;
        // sortby/search 參數是否要丟後端處理
        fetchPrememberList(this.merchantId, query)
          .then((res) => {
            items = [...res.data.premembers];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchPrememberList failed", e);
            this.$swal({
              text: "無法取得預會員資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 100);
          });
      });
    },

    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onReturnItemClick(prem) {
      this.$swal({
        title: `預會員回收 - ${prem.completeId}`,
        text: "是否確認回收預會員點數？",
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        console.log(res);
        if (res.isConfirmed) {
          this.onReturnItem(prem);
        }
      });
    },
    onReturnItem(prem) {
      refundPrememberPoint(this.merchantId, prem.completeId)
        .then((res) => {
          console.log("refundPrememberPoint done: ", res);
          this.$swal({
            title: "回收成功",
            html: `已成功回收預會員點數`,
            type: "success",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          prem.status = res.data.status;
          prem.updatedAt = res.data.updatedAt;
        })
        .catch((e) => {
          console.log("refundPrememberPoint failed", e);
          let errmsg = "無法回收預會員點數";
          if (e.response && e.response.data) {
            if (e.response.data.error && e.response.data.error.message) {
              errmsg += "<br/>" + e.response.data.error.message;
            } else if (e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
          }
          const title = "回收失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
  },
};
</script>
<style scoped>
.text-wrap-whitespace {
  white-space: pre-wrap;
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word; */
}
</style>
