var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 預會員列表 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"搜尋外部ID","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('span',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"\n                  font-weight-normal\n                  text-capitalize\n                  btn-primary\n                  bg-gradient-secondary\n                  py-3\n                  px-6\n                  ms-3\n                ",attrs:{"disabled":!_vm.isLoaded},on:{"click":function($event){_vm.pagination.page = 1;
                  _vm.getDataFromApi();}}},[_vm._v(" "+_vm._s(_vm.$t("common[\"Update\"]"))+" ")])],1)],1),_c('v-card-text',[_c('v-data-table',{ref:"prememberTable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":!_vm.isLoaded,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"item-key":"id","options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
                'items-per-page-text': _vm.$t("common['Rows per page']"),
              },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.status === 'queued')?_c('span',[_vm._v(" 新增 ")]):(item.status === 'reserved')?_c('span',[_vm._v(" 預會員 ")]):(item.status === 'completed')?_c('span',[_vm._v(" 已完成 ")]):(item.status === 'cancelled')?_c('span',[_vm._v(" 已取消 ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.moment .unix(item.createdAt) .format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.updatedAt",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updatedAt ? _vm.moment .unix(item.updatedAt) .format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.actions",fn:function(props){return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","small":"","color":"blue darken-5","outlined":"","disabled":props.item.status !== 'reserved'},on:{"click":function($event){return _vm.onReturnItemClick(props.item)}}},[_vm._v(" 回收 ")])]}}],null,true)},[_c('span',[_vm._v("回收")])]):_vm._e()]}}])})],1),(_vm.total > 0)?_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.total,"loading":!_vm.isLoaded,"showItemsPerPage":"","dataTable":"prememberTable"},on:{"update":_vm.getDataFromApi}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }